// import React from "react";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   ResponsiveContainer,
// } from "recharts";

// const data = [
//   { name: "Humalog", value1: 1157, value2: 5838 },
//   { name: "Novolog", value1: 789, value2: 618 },
//   { name: "Ozempic", value1: 727, value2: 445 },
//   { name: "Diabeta", value1: 512, value2: 437 },
//   { name: "Victoza", value1: 396, value2: 239 },
//   { name: "Apidra", value1: 297, value2: 202 },
//   { name: "Byetta", value1: 265, value2: 121 },
//   { name: "Trulicity", value1: 234, value2: 109 },
//   { name: "Asparat", value1: 91, value2: 101 },
//   { name: "Fiasp", value1: 73, value2: 80 },
// ];

// const DiabetesDrugsChart = ({ data, title, dualBars }) => {
//   return (
//     <div style={{ width: "100%", height: 300 }}>
//       <h3 className="chart-title">{title}</h3>
//       <ResponsiveContainer width="100%" height="100%">
//         <BarChart layout="vertical" data={data}>
//           <XAxis
//             type="number"
//             tick={false}
//             tickFormatter={(value) => `value: ${value}`}
//           />
//           <YAxis
//             dataKey="name"
//             type="category"
//             tick={{ fill: "#000", fontSize: "12px" }}
//             width={80} // Adjust width as needed for label visibility
//           />
//           {dualBars ? (
//             <>
//               <Bar dataKey="value1" fill="#bc7ac4" />
//               <Bar dataKey="value2" fill="#8884d8" />
//             </>
//           ) : (
//             <Bar dataKey="value" fill="#bc7ac4" />
//           )}
//         </BarChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export const DiabetesDrugsChart1 = () => (
//   <DiabetesDrugsChart data={data} title="Diabetes with Weightloss" dualBars />
// );

// export const DiabetesDrugsChart2 = () => (
//   <DiabetesDrugsChart data={data} title="Diabetes" dualBars/>
// );

// export const DiabetesDrugsChart3 = () => (
//   <DiabetesDrugsChart data={data} title="Gliptins" dualBars />
// );


// -------------------//Actual Code//------------------------//
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "Humalog", value: 1157 },
  { name: "Novolog", value: 789 },
  { name: "Ozempic", value: 727 },
  { name: "Diabeta", value: 512 },
  { name: "Victoza", value: 396 },
  { name: "Apidra", value: 297 },
  { name: "Byetta", value: 265 },
  { name: "Trulicity", value: 234 },
  { name: "Asparat", value: 91 },
  { name: "Fiasp", value: 73 },
];
const data2 = [
  { name: "Glucophage", value: 5838 },
  { name: "Levemir", value: 618 },
  { name: "Glimepride", value: 445 },
  { name: "Actos", value: 437 },
  { name: "Jardiance", value: 239 },
  { name: "Invokana", value: 202 },
  { name: "Basalgar", value: 121 },
  { name: "Glyburide", value: 109 },
  { name: "Glimpepiride", value: 101 },
  { name: "Amaryl", value: 80 },
];
const data3 = [
  { name: "Januvia", value: 357 },
  { name: "Janumet", value: 67 },
  { name: "Oseni", value: 52 },
  { name: "Pioglitazone", value: 49 },
  { name: "Nesina", value: 47 },
  { name: "Jentadueto", value: 27 },
  { name: "Tradjenta", value: 22 },
  { name: "Onglyza", value: 20 },
  { name: "Evogliptin", value: 17 },
  { name: "Omarigliptin", value: 12 },
];

const DiabetesDrugsChart = ({ data, title }) => {
  return (
    <div style={{ width: "100%", height: 300 }}>
      <h3 className="chart-title">{title}</h3>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart layout="vertical" data={data}>
          <XAxis
            type="number"
            tick={false}
            tickFormatter={(value) => `value: ${value}`}
          />
          <YAxis
            dataKey="name"
            type="category"
            tick={{ fill: "#000", fontSize: "12px" }}
            width={80} // Adjust width as needed for label visibility
          />
          {/* Tooltip removed */}
          <Bar
            dataKey="value"
            fill="#bc7ac4"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export const DiabetesDrugsChart1 = () => (
  <DiabetesDrugsChart data={data} title="Diabetes with Weightloss" />
);
export const DiabetesDrugsChart2 = () => (
  <DiabetesDrugsChart data={data2} title="Diabetes" />
);
export const DiabetesDrugsChart3 = () => (
  <DiabetesDrugsChart data={data3} title="Gliptins" />
);

import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./content.css";
// import {
//   Verbalcommunications1,
//   Verbalcommunications2,
//   Verbalcommunications3,
// } from "./VerbalComm";
// import { Brochure1, Brochure2, Brochure3 } from "./Brochure";
import { AdsChart1, AdsChart2, AdsChart3 } from "./AdsChart";
import { WebsiteChart1, WebsiteChart2, WebsiteChart3 } from "./WebsiteChart";
import jsonData from "./conversion.json"; // Import your JSON data file
import CommentsSection from "./CommentSection";

// Function to filter data by category
const filterDataByCategory = (category) => {
  return jsonData.filter((item) => item.Category === category);
};
const data = [
  {
      "name": "Ozempic",
      "percentage": "11.61%"
  },
  {
      "name": "Januvia",
      "percentage": "9.46%"
  },
  {
      "name": "Jardiance",
      "percentage": "8.81%"
  },
  {
      "name": "Victoza",
      "percentage": "7.73%"
  },
  {
      "name": "Glucophage",
      "percentage": "6.86%"
  },
  {
      "name": "Trulicity",
      "percentage": "6.22%"
  },
  {
      "name": "Wegovy",
      "percentage": "4.51%"
  },
  {
      "name": "Saxenda",
      "percentage": "3.86%"
  },
  {
      "name": "Fortamet",
      "percentage": "3.43%"
  },
  {
      "name": "Mounjaro",
      "percentage": "3%"
  },
  {
      "name": "Invokana",
      "percentage": "2.78%"
  },
  {
      "name": "Byetta",
      "percentage": "2.57%"
  },
  {
      "name": "Farxiga",
      "percentage": "2.14%"
  },
  {
      "name": "Levemir",
      "percentage": "1.93%"
  },
  {
      "name": "Janumet",
      "percentage": "1.71%"
  },
  {
      "name": "Onglyza",
      "percentage": "1.5%"
  },
  {
      "name": "Humalog",
      "percentage": "1.29%"
  },
  {
      "name": "Novolog",
      "percentage": "1.07%"
  },
  {
      "name": "Basaglar",
      "percentage": "0.64%"
  },
  {
      "name": "Fiasp",
      "percentage": "0.43%"
  },
  {
      "name": "Aspart",
      "percentage": "0.34%"
  },
  {
      "name": "Apidra",
      "percentage": "0.26%"
  },
  {
      "name": "Nesina",
      "percentage": "0.21%"
  },
  {
      "name": "Steglatro",
      "percentage": "0.17%"
  },
  {
      "name": "Evogliptin",
      "percentage": "0.09%"
  },
  {
      "name": "Omarigliptin",
      "percentage": "0.04%"
  },
  {
      "name": "Jentadueto",
      "percentage": "0.04%"
  }
]
export default function ProductPage() {

const comment1 = [
  {
      "Content": "I've been on Januvia for about six months, and honestly, it's been a lifesaver! My blood sugar levels are way better. However, I sometimes feel a bit nauseous after taking it, but it’s manageable.",
      "Platform": "Reddit"
  },
  {
      "Content": "Jardiance has changed my life! My A1C dropped from 8.5 to 6.9, and I’ve even lost 15 pounds. I do have to run to the bathroom a lot, which can be annoying, but I think it's worth it for the results!",
      "Platform": "Reddit"
  },
  {
      "Content": "Glucophage has been my go-to for years. It keeps my blood sugar in check, but let me tell you, the stomach issues can be pretty rough at times. Still, it’s better than the alternative!",
      "Platform": "Reddit"
  },
  {
      "Content": "Farxiga really helped me get my blood sugar under control. I’ve noticed fewer spikes, which is awesome! But I've had some weird cravings lately, and that’s been frustrating.",
      "Platform": "Reddit"
  },
  {
      "Content": "Invokana has worked wonders for my energy levels! I used to feel so sluggish, but now I’m more active. On the downside, I've had some yeast infections, which is a real bummer.",
      "Platform": "Reddit"
  },
  {
      "Content": "Lantus has been a reliable insulin for me. I like that it’s just once a day, but I’ve had a couple of episodes where my levels dipped too low. It’s scary, but I’m learning to manage it.",
      "Platform": "Reddit"
  },
  {
      "Content": "Switching to Levemir was a great choice! My blood sugar is pretty steady now, but I find the injections a bit painful sometimes. It’s annoying, but I guess it’s a trade-off.",
      "Platform": "Reddit"
  },
  {
      "Content": "I really love Ozempic! My blood sugar has improved, and I've lost around 10 pounds. But I’ve had a few days where I felt super nauseous, and that was no fun at all.",
      "Platform": "Reddit"
  },
  {
      "Content": "Trulicity is so easy to use, but I wish it worked a bit faster for me. My blood sugar has come down some, but I still have days where I feel hungry all the time. It’s a mixed bag!",
      "Platform": "Reddit"
  },
  {
      "Content": "Mounjaro has been fantastic! My blood sugar is the best it’s been in years, and I've even dropped a few pounds. However, I’ve experienced some stomach upset, so it’s not all sunshine and rainbows.",
      "Platform": "Reddit"
  }
];
const comment2 = [
  {
      "Content": "Been on Januvia for a few months now. It helps my blood sugar, but damn, I get nauseous after meals sometimes. Just what I needed, right?",
      "Platform": "Reddit"
  },
  {
      "Content": "Jardiance is decent for my A1C, but I swear I’m living in the bathroom. Like, can a person get a break? It's a bit embarrassing when I’m out and about!",
      "Platform": "Reddit"
  },
  {
      "Content": "Glucophage has been my go-to for ages, but the stomach issues are REAL. I’ve become best friends with my restroom. Honestly, it’s kind of a pain.",
      "Platform": "Reddit"
  },
  {
      "Content": "Farxiga does a solid job on my blood sugar, but the cravings are wild! Plus, I’ve felt a bit dizzy at times. Not sure if it’s the meds or just me.",
      "Platform": "Reddit"
  },
  {
      "Content": "Invokana is good for energy, but wow, the yeast infections are no joke. I didn’t sign up for that. It’s super annoying!",
      "Platform": "Reddit"
  },
  {
      "Content": "Lantus has been okay, but I’ve had some crazy lows that leave me shaky and anxious. It freaks me out! Still trying to nail down the right dosage.",
      "Platform": "Reddit"
  },
  {
      "Content": "Levemir works for my blood sugar, but sometimes the injection sites hurt like hell. Why can't it just be easy? Better than feeling out of control, though.",
      "Platform": "Reddit"
  },
  {
      "Content": "Ozempic has been a game changer for my sugar, but I’ve had days where I feel super nauseous. Skipped meals way too often. Anyone else?",
      "Platform": "Reddit"
  },
  {
      "Content": "Trulicity is pretty easy to use, but those stomach cramps hit me like a truck sometimes. It’s not fun, especially when I'm out with friends.",
      "Platform": "Reddit"
  },
  {
      "Content": "Mounjaro has done wonders for my diabetes, but the stomach upset is no joke. It’s hit me out of nowhere and ruined some plans. Ugh!",
      "Platform": "Reddit"
  }
];
 //-------------//Handler Functions//-----------//
 let navigate = useNavigate();
 const contentHandler = () => {
  var selectedValue = document.getElementById("disease").value;
  if(selectedValue === 'disease1' ){
    navigate('/content');
  }
  else if(selectedValue === 'disease2' ){
    // navigate('/RhemmatoidArthritis');
  }
  else if(selectedValue === 'disease3'){
    // navigate('/PsoriaticArthritis');
  }
  //  navigate("/content");
 };
 const summaryHandler = () => {
  var selectedValue = document.getElementById("disease").value;
  if(selectedValue === 'disease1' ){
    navigate('/summary');
  }
  else if(selectedValue === 'disease2' ){
    // navigate('/RhemmatoidArthritis');
  }
  else if(selectedValue === 'disease3'){
    // navigate('/PsoriaticArthritis');
  }
  //  navigate("/summary");
 };
 const supportHandler=()=>{
  var selectedValue = document.getElementById("disease").value;
  if(selectedValue === 'disease1' ){
    navigate('/support');
  }
  else if(selectedValue === 'disease2' ){
    // navigate('/RhemmatoidArthritis');
  }
  else if(selectedValue === 'disease3'){
    // navigate('/PsoriaticArthritis');
  }
  //  navigate("/support");
 }
 const productHandler=()=>{
  var selectedValue = document.getElementById("disease").value;
  if(selectedValue === 'disease1' ){
    navigate('/product');
  }
  else if(selectedValue === 'disease2' ){
    // navigate('/RhemmatoidArthritis');
  }
  else if(selectedValue === 'disease3'){
    // navigate('/PsoriaticArthritis');
  }
  //  navigate("/product");
 }
 const trialHandler=()=>{
  var selectedValue = document.getElementById("disease").value;
  if(selectedValue === 'disease1' ){
    navigate('/trial');
  }
  else if(selectedValue === 'disease2' ){
    // navigate('/RhemmatoidArthritis');
  }
  else if(selectedValue === 'disease3'){
    // navigate('/PsoriaticArthritis');
  }
  //  navigate("/trial");
 }
 const handleRhAr=(event)=>{
  const selectedValue = event.target.value;
  console.log(selectedValue);
  if(selectedValue === 'disease1' ){
    navigate('/TypeDiabetes');
  }
  else if(selectedValue === 'disease2' ){
    navigate('/RhemmatoidArthritis');
  }
  else if(selectedValue === 'disease3'){
    navigate('/PsoriaticArthritis');
  }
  
}
 //---------// End of Handler Funtions //-----------//

  // Filter data for each category
  const websiteData = filterDataByCategory("Website");
  const adsData = filterDataByCategory("TV Ads");
  // const brochureData = filterDataByCategory("Brochure");
  // const verbalCommunicationsData = filterDataByCategory("Verbal Communications");

  return (
    <>
      <Container>
        <div className="nav-container">
        <span className="ms-2" style={{cursor:"pointer"}} onClick={()=>navigate('/')}><span className="nav-title">Patient Centricity</span></span>
          <div className="mt-2 p-3 filter-container2">
            <form>
            <select id="disease" name="disease" className="forum-container" onChange={handleRhAr} >
                  <option value="" disabled selected>
                    Filter by disease
                  </option>
                  <option value="disease1">Type II Diabetes</option>
                  <option value="disease2" >Rheumatoid Arthritis</option>
                  <option value="disease3">Psoriatic Arthritis</option>
                </select>
            </form>
            <span className="menu-container" onClick={summaryHandler}>
                Summary
              </span>
              <span className="menu-container" onClick={supportHandler}>
                Support
              </span>
              <span className="menu-container" onClick={contentHandler}>
                Content
              </span>
              <span className="menu-container" onClick={productHandler}>
                Product
              </span>
              <span className="menu-container" onClick={trialHandler}>
                Trial
              </span>
          </div>
        </div>

        <Container>
          <Row>
            <Col lg={3}>
              <Card className="p-2 m-2">
                <div className="title">TOTAL FORUMS</div>
                <div className="number">20</div>
              </Card>
            </Col>

            <Col lg={3}>
              <Card className="p-2 m-2">
                <div className="title">TOTAL DISCUSSIONS</div>
                <div className="number">1,20,000</div>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="p-2 m-2">
                <div className="title">TOTAL COMMENTS</div>
                <div className="number">11,000,000</div>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="p-2 m-2">
                <div className="title">AVG ENGAGEMENT</div>
                <div className="number">8/thread</div>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* <Container>
          <Row>
            <Col lg={4}>
              <Card className="mt-4 p-3">
                <span className="top-forum">Segment</span>
                <span className="forum-container">Efficacy</span>
                <span className="forum-container">Side-Effects</span>
              </Card>
            </Col>
            <Col lg={8}>
              <Card className="mt-4 p-3">
                <span className="top-forum">Top Brands</span>
                <Row>
                  <Col lg={4}>
                    {[
                      { name: "Humalog", percentage: "5.937%" },
                      { name: "Novolog", percentage: "4.048%" },
                      { name: "Ozempic", percentage: "3.797%" },
                      { name: "Diabeta", percentage: "0.05%" },
                      { name: "Victoza", percentage: "2.02%" },
                      { name: "Apidra", percentage: "1.517%" },
                      { name: "Byetta", percentage: "1.355%" },
                      { name: "Trulicity", percentage: "1.28%" },
                      { name: "Aspart", percentage: "0.575%" },
                      { name: "Fiasp", percentage: "0.363%" },
                    ].map((drug, index) => (
                      <div key={index} className="forum-container">
                        <span>{drug.name}</span>
                        <span>{drug.percentage}</span>
                      </div>
                    ))}
                  </Col>

                  <Col lg={4}>
                    {[
                      { name: "Glucophage", percentage: "0.549%" },
                      { name: "Levemir", percentage: "3.167%" },
                      { name: "Glimepride", percentage: "0.513%" },
                      { name: "Actos", percentage: "2.238%" },
                      { name: "Jardiance", percentage: "1.22%" },
                      { name: "Invokana", percentage: "1.032%" },
                      { name: "Basalgar", percentage: "0.616%" },
                      { name: "Glyburide", percentage: "0.554%" },
                      { name: "Glimpepiride", percentage: "0.54%" },
                      { name: "Amaryl", percentage: "0.405%" },
                    ].map((drug, index) => (
                      <div key={index} className="forum-container">
                        <span>{drug.name}</span>
                        <span>{drug.percentage}</span>
                      </div>
                    ))}
                  </Col>
                  <Col lg={4}>
                    {[
                      { name: "Januvia", percentage: "1.828%" },
                      { name: "Janumet", percentage: "0.477%" },
                      { name: "Oseni", percentage: "0.133%" },
                      { name: "Fortamet", percentage: "0.046%" },
                      { name: "Nesina", percentage: "0.051%" },
                      { name: "Jentadueto", percentage: "0.035%" },
                      { name: "Tradjenta", percentage: "0.128%" },
                      { name: "Onglyza", percentage: "0.179%" },
                      { name: "Evogliptin", percentage: "0.09%" },
                      { name: "Omarigliptin", percentage: "0.04%" },
                    ].map((drug, index) => (
                      <div key={index} className="forum-container">
                        <span>{drug.name}</span>
                        <span>{drug.percentage}</span>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container> */}
        <Container className="mt-2 p-3">
          <Row>
            <Col lg={4} style={{padding:"inherit"}} className="h-100">
              <Card className="p-3">
                <span className="top-forum">Segment</span>
                {[
                  { name: "Efficacy" },
                  { name: "Side-Effects" },
                ].map((forum, index) => (
                <div key={index}>
                  <span className="d-inline-block pt-3">{forum.name}</span>
                </div>
                  // <div key={index} className="forum-container">
                  //   <span>{forum.name}</span>
                  //   <span>{forum.percentage}</span>
                  // </div>
                ))}
              </Card>
            </Col>

            <Col lg={8}>
              <Card className="p-3">
                <span className="top-forum">Top Brands</span>
                <Row>
                  <Col lg={4}>
                    {data.slice(0,10).map((drug, index) => (
                      <div key={index}>
                        <span className="d-inline-block pt-3" style={{width:"60%"}}>{drug.name}</span>
                        <span className="d-inline-block">{drug.percentage}</span>
                      </div>
                    ))}
                  </Col>

                  <Col lg={4}>
                    {data.slice(10,20).map((drug, index) => (
                    <div key={index}>
                      <span className="d-inline-block pt-3" style={{width:"60%"}}>{drug.name}</span>
                      <span className="d-inline-block">{drug.percentage}</span>
                    </div>
                    //   <div key={index} className="forum-container">
                    //   <span>{drug.name}</span>
                    //   <span>{drug.percentage}</span>
                    // </div>
                    ))}
                  </Col>
                  <Col lg={4}>
                    {data.slice(20,30).map((drug, index) => (
                      <div key={index}>
                        <span className="d-inline-block pt-3" style={{width:"60%"}}>{drug.name}</span>
                        <span className="d-inline-block">{drug.percentage}</span>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container className="mt-2 p-3">
          <Card>
            <Row>
              <h6 className="chart-title m-3">Efficacy</h6>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <WebsiteChart1 data={websiteData} />
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <WebsiteChart2 data={websiteData} />
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <WebsiteChart3 data={websiteData} />
                </Card>
              </Col>
              <Container>
        <Row>
          {comment1.map((comment, index) => (
            <Col lg={12} key={index} className="mb-4"> {/* Change lg={12} to occupy the full row */}
              <Card className="p-3">
                <Card.Body>
                  <Card.Text>{comment.Content}</Card.Text>
                  <Button style={{color:"#ad33079d",backgroundColor:"#f4beab"}}>{comment.Platform}</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
            </Row>
            <Row>
              <h6 className="chart-title m-3">Side Effects</h6>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <AdsChart1 data={adsData} />
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <AdsChart2 data={adsData} />
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <AdsChart3 data={adsData} />
                </Card>
              </Col>
              <Container>
        <Row>
          {comment2.map((comment, index) => (
            <Col lg={12} key={index} className="mb-4"> {/* Change lg={12} to occupy the full row */}
              <Card className="p-3">
                <Card.Body>
                  <Card.Text>{comment.Content}</Card.Text>
                  <Button style={{color:"#ad33079d",backgroundColor:"#f4beab"}}>{comment.Platform}</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
            </Row>
          </Card>
        </Container>
      </Container>
    </>
  );
}

import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./content.css";
// import {
//   Verbalcommunications1,
//   Verbalcommunications2,
//   Verbalcommunications3,
// } from "./VerbalComm";
import { Brochure1, Brochure2, Brochure3 } from "./Brochure";
import { AdsChart1, AdsChart2, AdsChart3 } from "./AdsChart";
import { WebsiteChart1, WebsiteChart2, WebsiteChart3 } from "./WebsiteChart";
import jsonData from "./conversion.json"; // Import your JSON data file
import CommentsSection from "./CommentSection";

// Function to filter data by category
const filterDataByCategory = (category) => {
  return jsonData.filter((item) => item.Category === category);
};

export default function TrialPage() {

  const comment1 = [
    {
        "Content": "I just kicked off the Mounjaro trial, and while the team is super supportive, I’m really struggling with the diet they want me to follow. It feels so restrictive! I just hope I can stick with it.",
        "Platform": "Reddit"
    },
    {
        "Content": "Been in the Cagrilintide trial for a month now, and honestly, I’m feeling pretty mixed. The nausea has hit me hard, and even though the staff is great, it’s tougher than I thought. Not sure if this is worth it yet.",
        "Platform": "Reddit"
    },
    {
        "Content": "I just wrapped up my first month on Efpeglenatide, and I’m loving the energy boost! But I’ve also been dealing with some stomach issues that are pretty annoying. Just hoping the good stuff keeps coming!",
        "Platform": "Reddit"
    },
    {
        "Content": "Thinking about jumping into the NNC9204-1177 trial, but I’m freaking out a bit about the side effects. I’ve had bad reactions to meds before, so it’s making me hesitate. Anyone else feel this way?",
        "Platform": "Reddit"
    },
    {
        "Content": "The community vibe during my Mounjaro trial is awesome, but I’m finding it hard to keep up with everything. Sometimes I feel totally overwhelmed by what they expect. Just trying not to burn out!",
        "Platform": "Reddit"
    },
    {
        "Content": "Joined the Cagrilintide trial hoping to shed some pounds, but I’m honestly feeling a bit frustrated. The side effects are more than I thought, and it’s hard to stay positive when I’m not seeing results. Thinking about bailing.",
        "Platform": "Reddit"
    },
    {
        "Content": "I’m really happy about my weight loss on Efpeglenatide, but I’ve been super tired a lot. It’s tough to stay active when I just wanna nap. Trying to balance everything without losing my mind.",
        "Platform": "Reddit"
    },
    {
        "Content": "Being part of the NNC9204-1177 trial has its highs and lows. The team is cool, but I sometimes feel like just another number in the study. I wish there was a bit more personal touch—it’d make it feel more special.",
        "Platform": "Reddit"
    },
    {
        "Content": "I’m getting used to the Mounjaro trial, but the lifestyle changes are way harder than I expected. The staff is really supportive, but when I don’t see any progress, it can be pretty discouraging.",
        "Platform": "Reddit"
    },
    {
        "Content": "Looking back on my time in the Cagrilintide trial, I feel a mix of empowerment and frustration. Connecting with others has been awesome, but the side effects are rough and make me wonder if this is the right choice for me.",
        "Platform": "Reddit"
    }
];
const comment2 = [
  {
      "Content": "I just started the Mounjaro trial, and I have to say, the staff is super friendly. But I really wish they’d be more upfront about the diet. It feels super restrictive, and knowing the reasoning behind it would help me stick with it.",
      "Platform": "Reddit"
  },
  {
      "Content": "Been in the Cagrilintide trial for a month now, and honestly, I’m kind of torn. The team is great about explaining side effects, but man, this nausea is brutal! I could use a little more clarity on what to expect going forward.",
      "Platform": "Reddit"
  },
  {
      "Content": "I’m a month into the Efpeglenatide trial, and I’m really feeling the energy boost! But I wish they had warned me about the stomach issues beforehand. It’s frustrating to deal with stuff I wasn’t expecting.",
      "Platform": "Reddit"
  },
  {
      "Content": "Thinking about joining the NNC9204-1177 trial, but I’m pretty anxious about side effects. I’d feel way better if the team shared more about how they handle adverse reactions. Just want to know what I might be getting into.",
      "Platform": "Reddit"
  },
  {
      "Content": "The community vibe in my Mounjaro trial is awesome! But sometimes, I feel overwhelmed with what they expect from me. If the team was a bit clearer about everything, it would definitely take some pressure off.",
      "Platform": "Reddit"
  },
  {
      "Content": "I joined the Cagrilintide trial hoping to lose weight, but I’m honestly frustrated. The staff is pretty honest about side effects, but I wish they’d give me more info on how long it usually takes to see results. It’s hard to stay motivated!",
      "Platform": "Reddit"
  },
  {
      "Content": "I’m happy with my weight loss on Efpeglenatide, but I’ve been feeling super drained lately. It would be great if the trial team could share how common this is, just so I know I’m not the only one dealing with it.",
      "Platform": "Reddit"
  },
  {
      "Content": "Being part of the NNC9204-1177 trial has its ups and downs. The team is really nice, but I sometimes feel like just another number. If they were more open about the process, I think I’d feel a lot more connected.",
      "Platform": "Reddit"
  },
  {
      "Content": "Getting used to the Mounjaro trial is tougher than I thought! The lifestyle changes are intense. The staff is super supportive, but if they explained why I need to make these changes, it would help me stay on track.",
      "Platform": "Reddit"
  },
  {
      "Content": "Reflecting on my time in the Cagrilintide trial, I feel both empowered and confused. I’ve made some great connections, but I wish I had a clearer idea of what’s next. A little transparency would really help!",
      "Platform": "Reddit"
  }
];
const comment3 = [
  {
      "Content": "So, I just signed up for the Mounjaro trial, and honestly, I’m feeling a mix of excitement and nerves. I’ve read so many positive stories about it helping with weight loss, but I can’t shake the worry about potential side effects. Anyone else been through this? I’d love to hear what your experiences were like!",
      "Platform": "Reddit"
  },
  {
      "Content": "I’ve been thinking about joining a Cagrilintide trial, but I keep hearing mixed reviews about the support. I really want to feel like I have someone to talk to if I have questions or concerns. It’s a big step for me, and I’d love to hear if anyone has had a good experience or any tips on how to navigate it.",
      "Platform": "Reddit"
  },
  {
      "Content": "Right now, I’m in an Efpeglenatide trial, and I have to say, the support has blown me away! The staff checks in with me regularly, and they really seem to care about how I’m feeling throughout the process. It’s nice to know I’m not just another participant; they remember my name and my story. Has anyone else felt this level of care?",
      "Platform": "Reddit"
  },
  {
      "Content": "I just wrapped up my time in the NNC9204-1177 trial, and I have some mixed feelings. At the beginning, the support was fantastic—so many check-ins and encouragement. But as time went on, it felt like the focus shifted more to data collection than to us participants. I found myself feeling a bit lost toward the end. Did anyone else experience this?",
      "Platform": "Reddit"
  },
  {
      "Content": "I’m super hopeful about the Mounjaro trial! I’ve seen a ton of positive stories and testimonials, which really gives me hope. I’m curious, though—did anyone face side effects? I want to prepare myself and have a plan in place if I do. Any advice would be super helpful!",
      "Platform": "Reddit"
  },
  {
      "Content": "I’m really on the fence about the Cagrilintide trial. A friend of mine did it and said the support was great at first, but then it really dropped off. That worries me because I want to feel supported throughout the entire process. If anyone has insights or advice on how to make the most of it, I’d love to hear!",
      "Platform": "Reddit"
  },
  {
      "Content": "I just joined an Efpeglenatide trial, and wow, I’m genuinely impressed! The staff is so attentive and seems to really care about our thoughts and feelings. They even asked for our feedback during the trial, which makes me feel valued. It’s nice to be part of something where I feel heard. Anyone else have a similar experience?",
      "Platform": "Reddit"
  },
  {
      "Content": "Completing the NNC9204-1177 trial was quite an eye-opener for me. At first, the support was amazing—everyone was super helpful and encouraging. But as we got deeper into the trial, I felt like they started to focus more on the numbers and data than on us participants. I found myself wishing for that initial support again. Did anyone else feel that way?",
      "Platform": "Reddit"
  },
  {
      "Content": "Has anyone else noticed how supportive the staff is in the Mounjaro trial? I’ve really appreciated all the check-ins and the personal touch they bring. It feels like they’re genuinely invested in our progress, and that makes such a difference. I’m curious if others have had similar experiences.",
      "Platform": "Reddit"
  },
  {
      "Content": "I’m a bit hesitant about Cagrilintide. I’ve heard such mixed things about the support, and I really don’t want to feel lost if I have questions during the trial. It’s a big step for me, and I want to make sure I have someone to rely on throughout the process. Any tips from those who’ve been through it would be greatly appreciated!",
      "Platform": "Reddit"
  }
];
const data = [
  {
      "name": "Mounjaro",
      "percentage": "1.12%"
  },
  {
      "name": "Zynquista",
      "percentage": "0.92%"
  },
  {
      "name": "Tzield",
      "percentage": "0.64%"
  },
  {
      "name": "ORMD-0801 ",
      "percentage": "0.53%"
  },
  {
      "name": "Efpeglenatide ",
      "percentage": "0.39%"
  },
  {
      "name": "Cagrilintide ",
      "percentage": "0.31%"
  },
  {
      "name": "Lyumjev",
      "percentage": "0.29%"
  },
  {
      "name": "Pegylated Exendin-4 ",
      "percentage": "0.23%"
  },
  {
      "name": "Ilaris",
      "percentage": "0.16%"
  },
  {
      "name": "NNC9204-1177 ",
      "percentage": "0.05%"
  }
]
  //-------------//Handler Functions//-----------//
  let navigate = useNavigate();
  const contentHandler = () => {
    navigate("/content");
  };
  const summaryHandler = () => {
    navigate("/summary");
  };
  const supportHandler=()=>{
    navigate("/support");
  }
  const productHandler=()=>{
    navigate("/product");
  }
  const trialHandler=()=>{
    navigate("/trial");
  }
  const handleRhAr=(event)=>{
    const selectedValue = event.target.value;
    console.log(selectedValue);
    if(selectedValue === 'disease1' ){
      navigate('/TypeDiabetes');
    }
    else if(selectedValue === 'disease2' ){
      navigate('/RhemmatoidArthritis');
    }
    else if(selectedValue === 'disease3'){
      navigate('/PsoriaticArthritis');
    }
    
  }
  //---------// End of Handler Funtions //-----------//
  // Filter data for each category
  const websiteData = filterDataByCategory("Website");
  const adsData = filterDataByCategory("TV Ads");
  const brochureData = filterDataByCategory("Brochure");
  // const verbalCommunicationsData = filterDataByCategory("Verbal Communications");

  return (
    <>
      <Container>
        <div className="nav-container">
        <span className="ms-2" style={{cursor:"pointer"}} onClick={()=>navigate('/')}><span className="nav-title">Patient Centricity</span></span>
          <div className="mt-2 p-3 filter-container2">
            <form>
            <select id="disease" name="disease" className="forum-container" onChange={handleRhAr} >
                  <option value="" disabled selected>
                    Filter by disease
                  </option>
                  <option value="disease1">Type II Diabetes</option>
                  <option value="disease2" >Rheumatoid Arthritis</option>
                  <option value="disease3">Psoriatic Arthritis</option>
                </select>
            </form>
            <span className="menu-container" onClick={summaryHandler}>
                Summary
              </span>
              <span className="menu-container" onClick={supportHandler}>
                Support
              </span>
              <span className="menu-container" onClick={contentHandler}>
                Content
              </span>
              <span className="menu-container" onClick={productHandler}>
                Product
              </span>
              <span className="menu-container" onClick={trialHandler}>
                Trial
              </span>
          </div>
        </div>

        <Container>
          <Row>
            <Col lg={3}>
              <Card className="p-2 m-2">
                <div className="title">TOTAL FORUMS</div>
                <div className="number">20</div>
              </Card>
            </Col>

            <Col lg={3}>
              <Card className="p-2 m-2">
                <div className="title">TOTAL DISCUSSIONS</div>
                <div className="number">1,20,000</div>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="p-2 m-2">
                <div className="title">TOTAL COMMENTS</div>
                <div className="number">11,000,000</div>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="p-2 m-2">
                <div className="title">AVG ENGAGEMENT</div>
                <div className="number">8/thread</div>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* <Container>
          <Row>
            <Col lg={4}>
              <Card className="mt-4 p-3">
                <span className="top-forum">Segment</span>
                <span className="forum-container">Patient Centricity Sentiment</span>
                <span className="forum-container">Transparency</span>
                <span className="forum-container">Trial Support</span>
              </Card>
            </Col>
            <Col lg={8}>
              <Card className="mt-4 p-3">
                <span className="top-forum">Top Brands</span>
                <Row>
                  <Col lg={4}>
                    {[
                      { name: "Humalog", percentage: "5.937%" },
                      { name: "Novolog", percentage: "4.048%" },
                      { name: "Ozempic", percentage: "3.797%" },
                      { name: "Diabeta", percentage: "0.05%" },
                      { name: "Victoza", percentage: "2.02%" },
                      { name: "Apidra", percentage: "1.517%" },
                      { name: "Byetta", percentage: "1.355%" },
                      { name: "Trulicity", percentage: "1.28%" },
                      { name: "Aspart", percentage: "0.575%" },
                      { name: "Fiasp", percentage: "0.363%" },
                    ].map((drug, index) => (
                      <div key={index} className="forum-container">
                        <span>{drug.name}</span>
                        <span>{drug.percentage}</span>
                      </div>
                    ))}
                  </Col>

                  <Col lg={4}>
                    {[
                      { name: "Glucophage", percentage: "0.549%" },
                      { name: "Levemir", percentage: "3.167%" },
                      { name: "Glimepride", percentage: "0.513%" },
                      { name: "Actos", percentage: "2.238%" },
                      { name: "Jardiance", percentage: "1.22%" },
                      { name: "Invokana", percentage: "1.032%" },
                      { name: "Basalgar", percentage: "0.616%" },
                      { name: "Glyburide", percentage: "0.554%" },
                      { name: "Glimpepiride", percentage: "0.54%" },
                      { name: "Amaryl", percentage: "0.405%" },
                    ].map((drug, index) => (
                      <div key={index} className="forum-container">
                        <span>{drug.name}</span>
                        <span>{drug.percentage}</span>
                      </div>
                    ))}
                  </Col>
                  <Col lg={4}>
                    {[
                      { name: "Januvia", percentage: "1.828%" },
                      { name: "Janumet", percentage: "0.477%" },
                      { name: "Oseni", percentage: "0.133%" },
                      { name: "Fortamet", percentage: "0.046%" },
                      { name: "Nesina", percentage: "0.051%" },
                      { name: "Jentadueto", percentage: "0.035%" },
                      { name: "Tradjenta", percentage: "0.128%" },
                      { name: "Onglyza", percentage: "0.179%" },
                      { name: "Evogliptin", percentage: "0.09%" },
                      { name: "Omarigliptin", percentage: "0.04%" },
                    ].map((drug, index) => (
                      <div key={index} className="forum-container">
                        <span>{drug.name}</span>
                        <span>{drug.percentage}</span>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container> */}
        <Container className="mt-2 p-3">
          <Row>
            <Col lg={4} style={{padding:"inherit"}} className="h-100">
              <Card className="p-3">
                <span className="top-forum">Segment</span>
                {[
                  { name: "Patient Centricity Sentiment" },
                  { name: "Transparency" },
                  { name: "Trial Support" },
                ].map((forum, index) => (
                <div key={index}>
                  <span className="d-inline-block pt-3">{forum.name}</span>
                </div>
                  // <div key={index} className="forum-container">
                  //   <span>{forum.name}</span>
                  //   <span>{forum.percentage}</span>
                  // </div>
                ))}
              </Card>
            </Col>

            <Col lg={8}>
              <Card className="p-3">
                <span className="top-forum">Top Brands</span>
                <Row>
                  <Col lg={4}>
                    {data.slice(0,10).map((drug, index) => (
                      <div key={index}>
                        <span className="d-inline-block pt-3" style={{width:"60%"}}>{drug.name}</span>
                        <span className="d-inline-block">{drug.percentage}</span>
                      </div>
                    ))}
                  </Col>

                  <Col lg={4}>
                    {data.slice(10,20).map((drug, index) => (
                    <div key={index}>
                      <span className="d-inline-block pt-3" style={{width:"60%"}}>{drug.name}</span>
                      <span className="d-inline-block">{drug.percentage}</span>
                    </div>
                    //   <div key={index} className="forum-container">
                    //   <span>{drug.name}</span>
                    //   <span>{drug.percentage}</span>
                    // </div>
                    ))}
                  </Col>
                  <Col lg={4}>
                    {data.slice(20,30).map((drug, index) => (
                      <div key={index}>
                        <span className="d-inline-block pt-3" style={{width:"60%"}}>{drug.name}</span>
                        <span className="d-inline-block">{drug.percentage}</span>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container className="mt-2 p-3">
          <Card>
            <Row>
              <h6 className="chart-title m-3">Patient Centricity Sentiment</h6>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <WebsiteChart1 data={websiteData} />
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <WebsiteChart2 data={websiteData} />
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <WebsiteChart3 data={websiteData} />
                </Card>
              </Col>
              <Container>
        <Row>
          {comment1.map((comment, index) => (
            <Col lg={12} key={index} className="mb-4"> {/* Change lg={12} to occupy the full row */}
              <Card className="p-3">
                <Card.Body>
                  <Card.Text>{comment.Content}</Card.Text>
                  <Button style={{color:"#ad33079d",backgroundColor:"#f4beab"}}>{comment.Platform}</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
            </Row>
            <Row>
              <h6 className="chart-title m-3">Transparency</h6>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <AdsChart1 data={adsData} />
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <AdsChart2 data={adsData} />
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <AdsChart3 data={adsData} />
                </Card>
              </Col>
              <Container>
        <Row>
          {comment2.map((comment, index) => (
            <Col lg={12} key={index} className="mb-4"> {/* Change lg={12} to occupy the full row */}
              <Card className="p-3">
                <Card.Body>
                  <Card.Text>{comment.Content}</Card.Text>
                  <Button style={{color:"#ad33079d",backgroundColor:"#f4beab"}}>{comment.Platform}</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
            </Row>
            <Row>
              <h6 className="chart-title m-3">Trial Support</h6>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <Brochure1 data={brochureData} />
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <Brochure2 data={brochureData} />
                </Card>
              </Col>
              <Col lg={4}>
                <Card className="p-3 m-2">
                  <Brochure3 data={brochureData} />
                </Card>
              </Col>
              <Container>
        <Row>
          {comment3.map((comment, index) => (
            <Col lg={12} key={index} className="mb-4"> {/* Change lg={12} to occupy the full row */}
              <Card className="p-3">
                <Card.Body>
                  <Card.Text>{comment.Content}</Card.Text>
                  <Button style={{color:"#ad33079d",backgroundColor:"#f4beab"}}>{comment.Platform}</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
            </Row>
          </Card>
        </Container>
      </Container>
    </>
  );
}

import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { HashRouter, Route, Routes } from "react-router-dom";
// import Page1 from "./components/Page1";
import Page2 from "./components/Page2";
import Content from "./components/Content"
// import Communication from "./components/Communication";
import SentimentAnalysis from "./components/Communication";
import SummaryPage from "./components/SummaryPage";
import ProductPage from "./components/ProductPage";
import SupportPage from "./components/SupportPage";
import TrialPage from "./components/TrialPage";
import RhemmatoidArthritis from "./components/RhemmatoidArthritis";
import PsoriaticArthritis from "./components/PsoriaticArthritis";
import TypeDiabetes from "./components/TypeDiabetes";


function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Page2/>} />
        {/* <Route path="/page1" element={<Page1 />} /> */}
        <Route path="/page2" element={<Page2 />} />
        <Route
          path="/content"
          element={<Content />}
        />
        <Route
          path="/communication"
          element={<SentimentAnalysis />}
        />
        <Route
          path="/summary"
          element={<SummaryPage />}
        />
        <Route
          path="/product"
          element={<ProductPage />}
        />
        <Route
          path="/support"
          element={<SupportPage />}
        />
        <Route
          path="/trial"
          element={<TrialPage />}
        />
        <Route
          path="/RhemmatoidArthritis"
          element={<RhemmatoidArthritis />}
        />
        <Route
          path="/PsoriaticArthritis"
          element={<PsoriaticArthritis />}
        />
        <Route
          path="/TypeDiabetes"
          element={<TypeDiabetes />}
        />
      </Routes>
    </HashRouter>
  );
}

export default App;

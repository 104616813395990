import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  DiabetesDrugsChart1,
  DiabetesDrugsChart2,
  DiabetesDrugsChart3,
} from "./DiabetesDrugsChart";
import { useNavigate } from "react-router-dom";

import "./page2.css";
import HorizontalBarChartComponent from "./Clusteredgraph";

export default function RhemmatoidArthritis() {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const data2 = [
    { name: "Medication Adherence and Side Effects", value: 74779 },
    { name: "Efficacy of Different Medications", value: 56635 },
    { name: "Medication Adherence Challenges", value: 43887 },
    { name: "Comparing Oral Medications and Insulin", value: 19027 },
    { name: "New and Emerging Treatments", value: 8142 },
    { name: "Cost and Accessibility of Medications", value: 4918 },
  ];
  const CommentsData = [
    {
      Platform: "Reddit",
      Content:
        "They put him on 500mg 2x/day metformin. His insurance denied jardience. He tested last week A1C 8.5 Doctor did not increase his metformin.",
      Category: "Patient",
      Topic: "Support bucket",
      Subtopic: "Paperwork Support",
    },
    {
      Platform: "Diabetes Daily Forum",
      Content:
        "$25/ mo with Ozempic saving card. $45 without. I oddly get 2 pens a month though. I'm only on .5 and that's my therapeutic dose. So I fill it every other month.",
      Category: "Patient",
      Topic: "Support bucket",
      Subtopic: "Co-Pay Cards and Financial Support",
    },
    {
      Platform: "Reddit",
      Content:
        "I started on Ozempic recently, and the nurse training was so helpful. I wish they had more follow-ups because learning how to inject it correctly was a bit tricky.",
      Category: "Patient",
      Topic: "Support bucket",
      Subtopic: "Nurse Training Support",
    },
    {
      Platform: "Reddit",
      Content:
        "Invokana was prescribed, but dealing with the insurance paperwork is a nightmare. I am finally frustrated",
      Category: "Patient",
      Topic: "Support bucket",
      Subtopic: "Paperwork Support",
    },
    {
      Platform: "Reddit",
      Content:
        "The financial assistance for Jardiance barely made a dent in the price. I’m grateful for the co-pay card, but it’s still really expensive to stick with it.",
      Category: "Patient",
      Topic: "Support bucket",
      Subtopic: "Co-Pay Cards and Financial Support",
    },
    {
      Platform: "Reddit",
      Content:
        "Pharm tech here! If she is on personal (non government) insurance. She can go to website of medication (jardiance, farxiga ext) and apply for a discount card that will cap the co-pay to around $30ish a month!",
      Category: "HCP",
      Topic: "Communications bucket",
      Subtopic: "Difficulty Finding Information",
    },
    {
      Platform: "Reddit",
      Content:
        "No one's advertising ozempic to diabetics .. they know about it already. The ads are to target ppl for weight loss",
      Category: "Patient",
      Topic: "Communications bucket",
      Subtopic: "Perception of Advertisements",
    },
    {
      Platform: "Reddit",
      Content:
        "Here on Reddit, after joining this group, I got hit with an ad for a weight loss physician who could prescribe Ozempic. Clicked on it. Was connected to a physician who is in my state, set up an appointment on the website, filled out my health history, had a video/phone appointment, and was prescribed Ozempic.\nThe medicine arrived from the mail-order pharmacy a few weeks later. So yes, it's not the Ozempic that comes from the one pharmaceutical company with a patent... It's made by a compounding pharmacy, comes in a vial, not a pen.",
      Category: "Patient",
      Topic: "Communications bucket",
      Subtopic: "Difficulty Finding Information",
    },
    {
      Platform: "Reddit",
      Content:
        "Saw a commercial today and it was so generic. It doesn’t reflect what we really go through as Type 2 diabetics. These ads are always out of touch.",
      Category: "Patient",
      Topic: "Communications bucket",
      Subtopic: "Perception of Advertisements",
    },
    {
      Platform: "Reddit",
      Content:
        "The brochure I got from my doctor was so outdated. It didn’t cover half the questions I had about what to expect when starting the wegovy.",
      Category: "Patient",
      Topic: "Communications bucket",
      Subtopic: "Perception of Advertisements",
    },
    {
      Platform: "Reddit",
      Content:
        "I work in clinical research! If this is something you’re interested in, it could be a great way to keep track of your diabetes. $105 is great, but I’ve seen trials with less pay but fewer visits.",
      Category: "Patient",
      Topic: "Trial bucket",
      Subtopic: "Patient-Centricity Sentiment",
    },
    {
      Platform: "Reddit",
      Content:
        "I’ve participated in multiple trials. One was for an early version of a CGM. It was more for the science than the money for me. The pay was low, but the science part was worth it.",
      Category: "Patient",
      Topic: "Trial bucket",
      Subtopic: "Sentiment on Clinical Trials",
    },
    {
      Platform: "Carenity",
      Content:
        "I did a trial for a drug, can’t remember the name. It seemed to work well, but the tech was outdated. The experience was frustrating, especially with the constant issues with the PalmPilot.",
      Category: "Patient",
      Topic: "Trial bucket",
      Subtopic: "Transparency and Burden",
    },
    {
      Platform: "Reddit",
      Content:
        "I was in a trial around 2012. The real benefit was the free supplies—tons of insulin and strips, plus $50 per doctor visit. The process wasn’t too stressful, and I appreciated the support.",
      Category: "Patient",
      Topic: "Trial bucket",
      Subtopic: "Transparency and Support",
    },
    {
      Platform: "Type 2 diabetes forum",
      Content:
        "Last year, I did a trial for a new injection procedure. $100 per visit, 10 visits. The injection didn’t work",
      Category: "Patient",
      Topic: "Trial bucket",
      Subtopic: "Burden of Participation",
    },
    {
      Platform: "PatientLikeMe",
      Content:
        "I’m glad to see that this new diabetes drug was tested in diverse patient populations. It gives me confidence that it might work for people from different backgrounds, not just a specific group.",
      Category: "Patient",
      Topic: "Product bucket",
      Subtopic: "Diversity",
    },
    {
      Platform: "PatientLikeMe",
      Content:
        "It was frustrating that the side effects weren’t fully explained when I started on this medication. I had to find out the hard way. There needs to be more transparency from the pharma companies.",
      Category: "Patient",
      Topic: "Product bucket",
      Subtopic: "Transparency",
    },
    {
      Platform: "PatientLikeMe",
      Content:
        "Glucophage was promoted as suitable for everyone, but my experience was different. It doesn’t seem to fit people from all walks of life, especially with the lifestyle changes it requires. I have diarhhoea everyday",
      Category: "Patient",
      Topic: "Product bucket",
      Subtopic: "Walks of Life",
    },
    {
      Platform: "Reddit",
      Content:
        "The cost of this Ozempic. Even with insurance, it’s clear that it’s out of reach for many people from lower-income backgrounds. ",
      Category: "Patient",
      Topic: "Product bucket",
      Subtopic: "Socio-Economic Inclusion",
    },
    {
      Platform: "Reddit",
      Content: "Ozempic affordability remains a major issue for most people.",
      Category: "Patient",
      Topic: "Product bucket",
      Subtopic: "Socio-Economic Inclusion",
    },
  ];

  //-------------//Handler Functions//-----------//
  let navigate = useNavigate();
  const contentHandler = () => {
    var selectedValue = document.getElementById("disease").value;
    if(selectedValue === 'disease1' ){
      navigate('/content');
    }
    else if(selectedValue === 'disease2' ){
      // navigate('/RhemmatoidArthritis');
    }
    else if(selectedValue === 'disease3'){
      // navigate('/PsoriaticArthritis');
    }
    //  navigate("/content");
   };
   const summaryHandler = () => {
    var selectedValue = document.getElementById("disease").value;
    if(selectedValue === 'disease1' ){
      navigate('/summary');
    }
    else if(selectedValue === 'disease2' ){
      // navigate('/RhemmatoidArthritis');
    }
    else if(selectedValue === 'disease3'){
      // navigate('/PsoriaticArthritis');
    }
    //  navigate("/summary");
   };
   const supportHandler=()=>{
    var selectedValue = document.getElementById("disease").value;
    if(selectedValue === 'disease1' ){
      navigate('/support');
    }
    else if(selectedValue === 'disease2' ){
      // navigate('/RhemmatoidArthritis');
    }
    else if(selectedValue === 'disease3'){
      // navigate('/PsoriaticArthritis');
    }
    //  navigate("/support");
   }
   const productHandler=()=>{
    var selectedValue = document.getElementById("disease").value;
    if(selectedValue === 'disease1' ){
      navigate('/product');
    }
    else if(selectedValue === 'disease2' ){
      // navigate('/RhemmatoidArthritis');
    }
    else if(selectedValue === 'disease3'){
      // navigate('/PsoriaticArthritis');
    }
    //  navigate("/product");
   }
   const trialHandler=()=>{
    var selectedValue = document.getElementById("disease").value;
    if(selectedValue === 'disease1' ){
      navigate('/trial');
    }
    else if(selectedValue === 'disease2' ){
      // navigate('/RhemmatoidArthritis');
    }
    else if(selectedValue === 'disease3'){
      // navigate('/PsoriaticArthritis');
    }
    //  navigate("/trial");
   }
  const handleRhAr=(event)=>{
    const selectedValue = event.target.value;
    console.log(selectedValue);
    if(selectedValue === 'disease1' ){
      navigate('/TypeDiabetes');
    }
    else if(selectedValue === 'disease2' ){
      navigate('/RhemmatoidArthritis');
    }
    else if(selectedValue === 'disease3'){
      navigate('/PsoriaticArthritis');
    }
    
  }
  //---------// End of Handler Funtions //-----------//

  const handleCardClick = (topic) => {
    setSelectedTopic(topic);
  };
  const data3=[
    
    
    {
        "name": "Humira",
        "percentage": "15.25%"
    },
    {
        "name": "Enbrel",
        "percentage": "13.12%"
    },
    {
        "name": "Remicade",
        "percentage": "12.35%"
    },
    {
        "name": "Orencia",
        "percentage": "10.50%"
    },
    {
        "name": "Actemra",
        "percentage": "8.45%"
    },
    {
        "name": "Xeljanz",
        "percentage": "7.15%"
    },
    {
        "name": "Rinvoq",
        "percentage": "6.25%"
    },
    {
        "name": "Cimzia",
        "percentage": "5.20%"
    },
    {
        "name": "Simponi",
        "percentage": "4.35%"
    },
    {
        "name": "Simponi Aria",
        "percentage": "3.25%"
    },
    {
        "name": "Kineret",
        "percentage": "2.45%"
    },
    {
        "name": "Arava",
        "percentage": "2.10%"
    },
    {
        "name": "Plaquenil",
        "percentage": "2.05%"
    },
    {
        "name": "Imuran",
        "percentage": "2.00%"
    },
    {
        "name": "Trexall",
        "percentage": "1.95%"
    },
    {
        "name": "Otrexup",
        "percentage": "1.90%"
    },
    {
        "name": "Rasuvo",
        "percentage": "1.85%"
    },
    {
        "name": "Kevzara",
        "percentage": "1.75%"
    },
    {
        "name": "Stelara",
        "percentage": "1.65%"
    },
    {
        "name": "Tremfya",
        "percentage": "1.60%"
    },
    {
        "name": "Cosentyx",
        "percentage": "1.55%"
    },
    {
        "name": "Taltz",
        "percentage": "1.50%"
    },
    {
        "name": "Siliq",
        "percentage": "1.45%"
    },
    {
        "name": "Ilumya",
        "percentage": "1.40%"
    },
    {
        "name": "Skyrizi",
        "percentage": "1.35%"
    },
    {
        "name": "Benlysta",
        "percentage": "1.30%"
    },
    {
        "name": "Rheumatrex",
        "percentage": "1.25%"
    },
    {
        "name": "Avsola",
        "percentage": "1.15%"
    },
    {
        "name": "Inflectra",
        "percentage": "1.05%"
    },
    {
        "name": "Hadlima",
        "percentage": "1.00%"
    }
]
  // Adjusted filtering to match Topic
  const filteredComments = CommentsData.filter(
    (comment) => comment.Topic === selectedTopic
  );

  return (
    <>
      <Container fluid>
        <Container className="nav-container">
          <span className="ms-2" style={{cursor:"pointer"}} onClick={()=>navigate('/')}><span className="nav-title">Patient Centricity</span></span>
          <div className="nav-container">
            <div className="mt-2 p-3 filter-container">
              <form>
              <select id="disease" name="disease" className="forum-container" onChange={handleRhAr} >
                  <option value="" disabled selected>
                    Filter by disease
                  </option>
                  <option value="disease1">Type II Diabetes</option>
                  <option value="disease2" selected>Rheumatoid Arthritis</option>
                  <option value="disease3">Psoriatic Arthritis</option>
                </select>
              </form>
              <span className="menu-container" onClick={summaryHandler}>
                Summary
              </span>
              <span className="menu-container" onClick={supportHandler}>
                Support
              </span>
              <span className="menu-container" onClick={contentHandler}>
                Content
              </span>
              <span className="menu-container" onClick={productHandler}>
                Product
              </span>
              <span className="menu-container" onClick={trialHandler}>
                Trial
              </span>
            </div>
          </div>
        </Container>

        <Container>
          <Row>
            <Col lg={3}>
              <Card className="p-2 m-2">
                <div className="title">TOTAL FORUMS</div>
                <div className="number">8</div>
              </Card>
            </Col>

            <Col lg={3}>
              <Card className="p-2 m-2">
                <div className="title">TOTAL DISCUSSIONS</div>
                <div className="number">1,20,000</div>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="p-2 m-2">
                <div className="title">TOTAL COMMENTS</div>
                <div className="number">5,50,000</div>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="p-2 m-2">
                <div className="title">AVG ENGAGEMENT</div>
                <div className="number">4/thread</div>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container className="mt-2 p-3">
          <Row>
            <Col lg={4} style={{padding:"inherit"}} className="h-100">
              <Card className="p-3">
                <span className="top-forum">Top forums</span>
                {[
    {
        "name": "Reddit",
        "percentage": "20.73"
    },
    {
        "name": "Drugs.com",
        "percentage": "17.74"
    },
    {
        "name": "MyPsoriasisTeam",
        "percentage": "15.17"
    },
    {
        "name": "WebMD",
        "percentage": "12.61"
    },
    {
        "name": "HealthUnlocked",
        "percentage": "12.18"
    },
    {
        "name": "CreakyJoints",
        "percentage": "11.75"
    },
    {
        "name": "NPF Community",
        "percentage": "7.91"
    },
    {
        "name": "PatientLikeMe",
        "percentage": "1.92"
    }
].map((forum, index) => (
                <div key={index}>
                  <span className="d-inline-block pt-3" style={{width:"60%"}}>{forum.name}</span>
                  <span className="d-inline-block">{forum.percentage}</span>
                </div>
                  // <div key={index} className="forum-container">
                  //   <span>{forum.name}</span>
                  //   <span>{forum.percentage}</span>
                  // </div>
                ))}
              </Card>
            </Col>

            <Col lg={8}>
              <Card className="p-3">
                <span className="top-forum">Top Brands</span>
                <Row>
                  <Col lg={4}>
                    {data3.slice(0,10).map((drug, index) => (
                      <div key={index}>
                        <span className="d-inline-block pt-3" style={{width:"60%"}}>{drug.name}</span>
                        <span className="d-inline-block">{drug.percentage}</span>
                      </div>
                    ))}
                  </Col>

                  <Col lg={4}>
                    {data3.slice(10,20).map((drug, index) => (
                    <div key={index}>
                      <span className="d-inline-block pt-3" style={{width:"60%"}}>{drug.name}</span>
                      <span className="d-inline-block">{drug.percentage}</span>
                    </div>
                    //   <div key={index} className="forum-container">
                    //   <span>{drug.name}</span>
                    //   <span>{drug.percentage}</span>
                    // </div>
                    ))}
                  </Col>
                  <Col lg={4}>
                    {data3.slice(20,30).map((drug, index) => (
                      <div key={index}>
                        <span className="d-inline-block pt-3" style={{width:"60%"}}>{drug.name}</span>
                        <span className="d-inline-block">{drug.percentage}</span>
                      </div>
                    ))}
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
        
      </Container>
    </>
  );
}

import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  // Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "Humalog", value: 12, value2: 15 },
  { name: "Ozempic", value: 10, value2: 12 },
  { name: "Novolog", value: 8, value2: 10 },
  { name: "Byetta", value: 7, value2: 9 },
  { name: "Victoza", value: 6, value2: 7 },
  { name: "Trulicity", value: 4, value2: 6 },
  { name: "Diabeta", value: 3, value2: 4 },
  { name: "Apidra", value: 3, value2: 4 },
  { name: "Asparat", value: 3, value2: 3 },
  { name: "Fiasp", value: 3, value2: 3 },
];

// const CustomTooltip = ({ active, payload, label }) => {
//   if (active && payload && payload.length) {
//     return (
//       <div
//         style={{
//           backgroundColor: "rgba(0, 0, 0, 0.75)",
//           color: "#fff",
//           padding: "5px",
//           borderRadius: "5px",
//           fontSize: "12px",
//           lineHeight: "1", // Adjust line height to reduce space between lines
//         }}
//       >
//         <p style={{ margin: "0" }}>{label}</p>
//         <p style={{ margin: "0" }}>{`Positive: ${payload[0].value}`}</p>
//         {payload.length > 1 && (
//           <p style={{ margin: "0" }}>{`Negative: ${payload[1].value}`}</p>
//         )}
//       </div>
//     );
//   }
//   return null;
// };

const VerbalCommunicationsChart = ({ data, title }) => {
  return (
    <div style={{ width: "100%", height: 300 }}>
      <h3 className="chart-title">{title}</h3>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart layout="vertical" data={data}>
          <XAxis 
            type="number" 
            axisLine={{ stroke: "#ccc", strokeWidth: 0.5 }} // Thinner gray line
            tick={false} // Hide tick values
          />
          <YAxis
            dataKey="name"
            type="category"
            tick={{ fill: "#000", fontSize: "12px" }}
            width={80} // Adjust width as needed for label visibility
          />
          {/* <Tooltip content={<CustomTooltip />} /> */}
          <Bar dataKey="value" fill="#bc7ac4" barSize={20} />
          <Bar dataKey="value2" fill="#b431c4" barSize={20} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export const Verbalcommunications1 = () => (
  <VerbalCommunicationsChart data={data} title="Diabetes with Weightloss" />
);
export const Verbalcommunications2 = () => (
  <VerbalCommunicationsChart data={data} title="Diabetes" />
);
export const Verbalcommunications3 = () => (
  <VerbalCommunicationsChart data={data} title="Gliptins" />
);



//--------------------//Actual Code//------------------------//
// // DiabetesDrugsChart.js
// import React from "react";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   ResponsiveContainer,
// } from "recharts";

// const data = [
//     { name: "Ozempic", value:32 },
//     { name: "Humalog", value: 27 },
//     { name: "Victoza", value: 17 },
//     { name: "Trulicity", value: 12 },
//     { name: "Novolog", value: 7 },
//     { name: "Apidra", value: 7 },
//     { name: "Byetta", value: 5 },
//     { name: "Fiasp", value: 4 },
//     { name: "Glyburide", value: 3 },
//     { name: "Asparat", value: 3 },
//   ];
  
//   const data2 = [
//     { name: "Glocophage", value: 119 },
//     { name: "Levemir", value: 18 },
//     { name: "Jardiance", value: 17 },
//     { name: "Actos", value: 10 },
//     { name: "Invokana", value: 4 },
//     { name: "Glimepride", value: 3 },
//     { name: "Basalgar", value: 3 },
//     { name: "Diabeta", value: 3 },
//     { name: "Glimpepiride", value: 3 },
//     { name: "Amaryl", value: 3 },
//   ];
  
//   const data3 = [
//     { name: "Januvia", value: 17 },
//     { name: "Actos", value: 6 },
//     { name: "Janumet", value: 4 },
//     { name: "Tradjenta", value: 4 },
//     { name: "Oseni", value: 3 },
//     { name: "Nesina", value: 3 },
//     { name: "Jentadueto", value: 3 },
//     { name: "Onglyza", value: 3 },
//     { name: "Evogliptin", value: 3 },
//     { name: "Omarigliptin", value: 3 },
//   ];
  

// const VerbalCommunicationsChart = ({ data, title }) => {
//   return (
//     <div style={{ width: "100%", height: 300 }}>
//       <h3 className="chart-title">{title}</h3>
//       <ResponsiveContainer width="100%" height="100%">
//         <BarChart layout="vertical" data={data}>
//           <XAxis type="number" tick={false} />
//           <YAxis
//             dataKey="name"
//             type="category"
//             tick={{ fill: "#000", fontSize: "12px" }}
//             width={80} // Adjust width as needed for label visibility
//           />
//           <Tooltip />
//           <Bar dataKey="value" fill="#bc7ac4" />
//         </BarChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export const Verbalcommunications1 = () => (
//   <VerbalCommunicationsChart data={data} title="Diabetes with Weightloss" />
// );
// export const Verbalcommunications2 = () => (
//   <VerbalCommunicationsChart data={data2} title="Diabetes" />
// );
// export const Verbalcommunications3 = () => (
//   <VerbalCommunicationsChart data={data3} title="Gliptins" />
// );
